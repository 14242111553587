import React from 'react';
import {useIntl} from 'react-intl';
import {
  Alert,
  Card,
  Divider,
  Grid,
  Heading,
  Paragraph,
  ProgressMeter,
  TextButton,
} from '@hexa-ui/components';
import {Download} from '@hexa-ui/icons';
import {formatNumber} from '@Utils';

interface CustomCardFileUploaderProps {
  titleName?: string;
  textIdDescriptionOne: string;
  textIdDescriptionTwo: string;
  valueUploadLimit?: number;
  isDisabledDownloadTemplate: boolean;
  children: React.ReactNode;
  file?: File | null;
  hasEligiblePocs?: boolean;
  hasFeedbackCard?: boolean;
  status?: 'waiting' | 'loading' | 'processing' | 'done' | 'error';
  dataTestIdProcessingFeedbackCard?: string;
  dataTestIdProcessingFeedbackCardStatus?: string;
  dataTestIdDoneFeedbackCard?: string;
  dataTestIdDoneFeedbackCardInconsistencies?: string;
  dataTestIdDoneFeedbackCardExportError?: string;
  isDisabledExportError?: boolean;
  processedLines?: number;
  inconsistentLines?: number;
  csvLines?: number;
  handleExportInconsistentPocs?: () => void;
  handleTemplateDownload?: () => void;
}

const CustomCardFileUploader: React.FC<CustomCardFileUploaderProps> = ({
  titleName,
  textIdDescriptionOne,
  textIdDescriptionTwo,
  valueUploadLimit,
  isDisabledDownloadTemplate,
  children,
  file,
  hasEligiblePocs,
  hasFeedbackCard,
  status,
  dataTestIdProcessingFeedbackCard,
  dataTestIdProcessingFeedbackCardStatus,
  dataTestIdDoneFeedbackCard,
  dataTestIdDoneFeedbackCardInconsistencies,
  dataTestIdDoneFeedbackCardExportError,
  isDisabledExportError,
  processedLines,
  inconsistentLines,
  csvLines,
  handleExportInconsistentPocs,
  handleTemplateDownload,
}) => {
  const {formatMessage} = useIntl();

  return (
    <>
      <Grid.Item xs={9} style={{margin: '2rem auto 0'}}>
        <Card style={{padding: '1.5rem'}} border="medium" elevated="medium">
          <Grid.Container type="fluid" style={{margin: 0}}>
            <Grid.Item xs={12}>
              <Heading size="H4">
                {formatMessage({id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.FILE_UPLOAD'})}
              </Heading>
            </Grid.Item>
            <Grid.Item xs={12} style={{flexDirection: 'column'}}>
              <Paragraph
                data-testid="upload-description-one"
                size="xsmall"
                colortype="secondary"
                style={{marginTop: '0.5rem'}}
              >
                {formatMessage(
                  {
                    id: textIdDescriptionOne,
                  },
                  titleName ? {title: titleName} : {}
                )}
              </Paragraph>
              <Paragraph
                data-testid="upload-description-two"
                size="xsmall"
                colortype="secondary"
              >
                {formatMessage(
                  {
                    id: textIdDescriptionTwo,
                  },
                  valueUploadLimit ? {value: valueUploadLimit} : {}
                )}
              </Paragraph>
            </Grid.Item>

            <Grid.Item xs={12}>
              <TextButton
                data-testid="download-template"
                icon={Download}
                iconPosition="leading"
                size="small"
                style={{margin: '2rem 0 1rem 0'}}
                onClick={handleTemplateDownload}
                disabled={isDisabledDownloadTemplate}
              >
                {formatMessage({
                  id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.DOWNLOAD_TEMPLATE',
                })}
              </TextButton>
            </Grid.Item>

            <Grid.Item xs={12}>{children}</Grid.Item>

            {hasEligiblePocs && (
              <Alert
                style={{width: '100%', marginTop: '1rem'}}
                type="info"
                message={formatMessage({
                  id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.UPLOAD_INFO',
                })}
              />
            )}
          </Grid.Container>
        </Card>
      </Grid.Item>

      {hasFeedbackCard && file && status === 'processing' && (
        <Grid.Item
          xs={9}
          data-testid={dataTestIdProcessingFeedbackCard}
          style={{margin: '1.5rem auto 0'}}
        >
          <Card
            style={{width: '100%', padding: '1.5rem'}}
            border="medium"
            elevated="medium"
          >
            <Grid.Container type="fluid" style={{margin: 0}}>
              <Grid.Item xs={12}>
                <Heading size="H4">
                  {formatMessage({
                    id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.UPLOAD_ANALYZE_TITLE',
                  })}
                </Heading>
              </Grid.Item>

              <Grid.Item style={{marginTop: '2rem'}} xs={12}>
                <Paragraph
                  data-testid={dataTestIdProcessingFeedbackCardStatus}
                  style={{fontSize: '0.75rem'}}
                >
                  {processedLines && processedLines > 0
                    ? formatMessage(
                        {
                          id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.PROCESSED',
                        },
                        {
                          processed: formatNumber(processedLines),
                          total: formatNumber(csvLines),
                        }
                      )
                    : formatMessage({
                        id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.STARTING',
                      })}
                </Paragraph>
              </Grid.Item>
              <Grid.Item xs={12}>
                <ProgressMeter
                  data-testid="progress-meter"
                  maxValue={csvLines || 0}
                  value={processedLines || 0}
                />
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
      )}

      {hasFeedbackCard && file && status === 'done' && (
        <Grid.Item
          xs={9}
          data-testid={dataTestIdDoneFeedbackCard}
          style={{margin: '1.5rem auto 0'}}
        >
          <Card
            style={{width: '100%', padding: '1.5rem'}}
            border="medium"
            elevated="medium"
          >
            <Grid.Container type="fluid" style={{margin: 0}}>
              <Grid.Item xs={12}>
                <Heading size="H4">
                  {formatMessage({
                    id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.UPLOAD_ANALYZE_TITLE',
                  })}
                </Heading>
              </Grid.Item>

              <Grid.Item xs={12}>
                <div
                  style={{
                    marginTop: '1.5rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '80%',
                  }}
                >
                  <div>
                    <Paragraph size="small">
                      {formatMessage({
                        id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.POCS_READY',
                      })}
                    </Paragraph>
                    <Heading
                      data-testid={dataTestIdDoneFeedbackCardInconsistencies}
                      size="H4"
                    >
                      {formatNumber((csvLines ?? 0) - (inconsistentLines ?? 0))}
                    </Heading>
                  </div>

                  <Divider orientation="vertical" />

                  <div>
                    <Paragraph size="small">
                      {formatMessage({
                        id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.POCS_ERROR',
                      })}
                    </Paragraph>
                    <Heading
                      data-testid="pocs-with-error"
                      style={{color: '#C9201D'}}
                      size="H4"
                    >
                      {formatNumber(inconsistentLines)}
                    </Heading>
                  </div>
                </div>
              </Grid.Item>

              {(inconsistentLines ?? 0) > 0 && (
                <>
                  <Grid.Item xs={12}>
                    <Alert
                      style={{width: '100%', marginTop: '2rem'}}
                      type="warning"
                      message={formatMessage({
                        id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.UPLOADED_ALERT',
                      })}
                    />
                  </Grid.Item>

                  <Grid.Item xs={12}>
                    <TextButton
                      data-testid={dataTestIdDoneFeedbackCardExportError}
                      icon={Download}
                      iconPosition="leading"
                      size="small"
                      style={{margin: '2rem 0 1rem 0'}}
                      onClick={handleExportInconsistentPocs}
                      disabled={isDisabledExportError}
                    >
                      {formatMessage({
                        id: 'CAMPAIGN_ELIGIBLE_POCS.ADD.EXPORT_ERROR_POCS',
                      })}
                    </TextButton>
                  </Grid.Item>
                </>
              )}
            </Grid.Container>
          </Card>
        </Grid.Item>
      )}
    </>
  );
};

export default CustomCardFileUploader;
